import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/database";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCpfoY2rWR4dR7JFCK-qhOlpG-GMmGQ8vM",
  authDomain: "triafirm.firebaseapp.com",
  projectId: "triafirm",
  storageBucket: "triafirm.appspot.com",
  messagingSenderId: "339539807068",
  appId: "1:339539807068:web:8c48d63ad48b0994e64f99",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const database = firebaseApp.database();
export const firestore = firebaseApp.firestore();
export const firebaseAnalytics = firebaseApp.analytics();

export default firebase;
