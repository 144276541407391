import React, { useState } from "react";
import "./home.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { BsSearch, BsCodeSquare } from "react-icons/bs";
import { GrLaunch } from "react-icons/gr";
import { firestore } from "../../firebase";

function Home() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted, ref] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await firestore.collection("contactusdata").add({
      name: name,
      email: email,
      message: message,
    });
    setName("");
    setEmail("");
    setMessage("");
    setSubmitted(true);
  };

  return (
    <>
      <header class="bg-light" id="home">
        <nav class="navbar navbar-expand-lg navbar-light navbar-transparent">
          <div class="container">
            <a class="navbar-brand" href="#">
              <img
                className="img-fluid"
                style={{ height: "50px" }}
                src={require("../../assets/img/logo1.png").default}
                alt=""
                srcset=""
              />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#site-nav"
              aria-controls="site-nav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="site-nav">
              <ul class="navbar-nav text-sm-left ml-auto">
                <li class="nav-item">
                  <a class="nav-link" href="#home">
                    Home
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#howitwork">
                    How It works
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#about">
                    About Us
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#contact">
                    Contact
                  </a>
                </li>
                <li class="nav-item text-center">
                  <a
                    href="https://calendly.com/gaetano-grosso/30minmeeting"
                    target="_blank"
                    class="btn align-middle btn-outline-primary my-2 my-lg-0"
                  >
                    Book a free call
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <section class="jumbotron">
          <Container className="d-flex align-items-center showcasemargintop">
            <Row className="d-flex align-items-center">
              <Col className="rmt order-sm-1 order-2" lg="6">
                <div className="">
                  <div class="container">
                    <h1 class="display-4">Your Development Partner</h1>
                    <p class="lead my-4">
                      For Building Software And Connecting To Web3.0.
                    </p>
                    <p>
                      <a href="#howitwork" class="btn btn-lg btn-primary">
                        Learn More
                      </a>
                      <a href="#contact" class="btn btn-lg btn-outline-primary">
                        Get a Quote
                      </a>
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="rmt order-1" lg="6">
                <div className="">
                  <img
                    className="img-fluid"
                    src={require("../../assets/img/showcase.png").default}
                    alt=""
                    srcset=""
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </header>

      <section style={{ marginTop: "9rem" }} id="howitwork" class="jumbotron">
        <Container className="">
          <div className="">
            <h1 class="display-4">How It Works</h1>
          </div>
          <Row style={{ marginTop: "5rem" }} className="">
            <Col lg="4" className="">
              <div className="">
                <div class="container">
                  <div className="">
                    <div className="circle">
                      <BsSearch className="" size={50} />
                    </div>
                  </div>
                  <p class="lead font-weight-bold my-4">Discovery</p>
                  <p style={{ color: "#8A8A8A" }} class="my-4">
                    Discovery is a process of collecting and analyzing
                    information about the project, its intended market, audience
                    and product details.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" className="rmt">
              <div className="">
                <div class="container">
                  <div className="">
                    <div className="circle">
                      <BsCodeSquare className="" size={50} />
                    </div>
                  </div>
                  <p class="lead font-weight-bold my-4">
                    Design &amp; Development
                  </p>
                  <p style={{ color: "#8A8A8A" }} class="my-4">
                    Design is combining strategy and prototyping. We skin the
                    project with a rich UI/UX. Development is the process of
                    turning designs into functional code. We integrate necessary
                    smart contracts, tokens, APIs, etc.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" className="rmt">
              <div className="">
                <div class="container">
                  <div className="">
                    <div className="circle">
                      <GrLaunch className="" size={50} />
                    </div>
                  </div>
                  <p class="lead font-weight-bold my-4">Deployment</p>
                  <p style={{ color: "#8A8A8A" }} class="my-4">
                    Deployment is testing and launching of the project. We make
                    sure the project is tested and deployed on the client-side
                    so we avoid any risks.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="about" class="jumbotron mmt">
        <Container className="">
          <div className="text-center">
            <h1 class="display-4">About Us</h1>
          </div>
          <Row style={{ marginTop: "3rem" }}>
            <Col lg="6" className="d-flex align-items-center">
              <div className="">
                <div class="container">
                  <p class="lead my-4">
                    We Turn Your Idea Into A Reality. Triafirm is a team of
                    highly talented individuals that can execute deeply
                    strategic Software development. We can assist you from
                    Website, Ecommerce and App development to Blockchain
                    Development, dApps, DeFi, NFTs, NFT Marketplace, Dex,
                    Tokenomics. In order to better serve our clients, we’ve
                    optimized our processes and strategies to align with the
                    current market.
                  </p>
                  <div class="">
                    <p class="lead font-weight-bold">Our Mission</p>
                    <p style={{ color: "#8A8A8A" }}>
                      Our mission is to help companies and organizations in
                      developing the best software solution they need. We fight
                      for bridging web2.0 and web3.0 for a decentralized,
                      trustless and permissionless future.
                    </p>
                  </div>
                  <div class="">
                    <p class="lead font-weight-bold">Our Warranty</p>
                    <p style={{ color: "#8A8A8A" }}>
                      We are so convinced of our capabilities on delivery, that
                      we offer you a 100% refund warranty.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              className="d-flex align-items-center justify-content-center rmt"
              lg="6"
            >
              <div>
                <img
                  style={{ borderRadius: "2rem" }}
                  className="img-fluid"
                  src={require("../../assets/img/about.jpeg").default}
                  alt=""
                  srcset=""
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section>
        <Container className="">
          <Row className="">
            <Col lg="6" className="">
              <div className="">
                <div class="container">
                  <p class="lead font-weight-bold my-4">Our Mission</p>
                  <p style={{ color: "#8A8A8A" }} class="my-4">
                    Our mission is to help companies and organizations in
                    developing the best software solution they need. We fight
                    for bridging web2.0 and web3.0 for a decentralized,
                    trustless and permissionless future.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="6" className="rmt">
              <div className="">
                <div class="container">
                  <p class="lead font-weight-bold my-4">Our Warranty</p>
                  <p style={{ color: "#8A8A8A" }} class="my-4">
                    We are so convinced of our capabilities on delivery, that we
                    offer you a 100% refund warranty.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      <section style={{ marginTop: "5rem" }} className="infopanel">
        <Container className="">
          <Row className="">
            <Col
              lg="3"
              className="d-flex align-items-center justify-content-center"
            >
              <div className="text-center">
                <div class="infopanel-title">140+</div>
                <div class="">Happy Clients</div>
              </div>
            </Col>
            <Col
              lg="3"
              className="rmt d-flex align-items-center justify-content-center"
            >
              <div className="text-center">
                <div class="infopanel-title">280+</div>
                <div class="">Cases Done</div>
              </div>
            </Col>
            <Col
              lg="3"
              className="rmt d-flex align-items-center justify-content-center"
            >
              <div className="text-center">
                <div class="infopanel-title">8+</div>
                <div class="">Years Experience</div>
              </div>
            </Col>
            <Col
              lg="3"
              className="rmt d-flex align-items-center justify-content-center"
            >
              <div className="text-center">
                <div class="infopanel-title ">3</div>
                <div class="">Branches Worldwide</div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div id="milestone" className="collective-content">
        <Container style={{ marginTop: "5rem" }}>
          <div className="">
            <div className="text-center">
              <div className="text-center">
                <h1 class="display-4">Our Life Story</h1>
              </div>
              <div style={{ marginTop: "3rem" }} className="font-weight-bold">
                <div className="">
                  <Container data-aos="fade-up" data-aos-duration="2000">
                    <div className="timeline py-5">
                      <div className="containerr left">
                        <div className="content">
                          <h5 className="text-left py-3">
                            Development partner for NFT Projects
                          </h5>
                          <p
                            className="cfont text-justify"
                            style={{ fontSize: "1rem" }}
                          >
                            We started as a Development partner for NFT projects
                            during the 2021 bull run. We helped many communities
                            in smart contract development and deployment ,
                            bringing solutions with ERC721 and ERC1155.
                          </p>
                        </div>
                      </div>
                      <div className="containerr right">
                        <div className="content cfont">
                          <h5 className="text-left py-3">Software</h5>
                          <p
                            className="cfont text-justify"
                            style={{ fontSize: "1rem" }}
                          >
                            In the meantime we absorbed a software house in
                            India and started working in a new way to deliver
                            best software solutions to startups and
                            corporations.
                          </p>
                        </div>
                      </div>

                      <div className="containerr left">
                        <div className="content">
                          <h5 className="text-left py-3">Team Growth</h5>
                          <p
                            className="cfont text-justify"
                            style={{ fontSize: "1rem" }}
                          >
                            From May of 2022 our team started growing and we
                            started to focus on new blockchains and software
                            solutions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {/* <section style={{ marginTop: "5rem" }}>
        <div className="text-center">
          <h1 class="display-4">Awards and Wins</h1>
        </div>
        <Container className="mt-1">
          <Row className="">
            <Col
              lg="12"
              className="d-flex align-items-center justify-content-center"
            >
              <div className="">
                <img
                  style={{ borderRadius: "2rem" }}
                  className="img-fluid"
                  src={require("../../assets/img/awardlogo.png").default}
                  alt=""
                  srcset=""
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      <div class="section" id="contact">
        <div class="container">
          <div class="section-title text-center">
            <h1 class="display-4">Contact Us</h1>
            <p>Contact us and we'll get back to you within 24 hours.</p>
          </div>
          <Row>
            <Col lg="6">
              <div className="">
                <div className="">
                  <p className="">Address</p>
                  <div className="text-muted">
                    5307 Victoria Drive #467 Vancouver, BC V5P 3V6
                  </div>
                </div>
                <div className="mt-3">
                  <p className="">Email:</p>
                  <div className="text-muted">info@triafirm.com</div>
                </div>
                <div className="mt-3 d-flex">
                  <div className="">
                    <p className="">Phone:</p>
                    <div className="text-muted">+1 215-606-0391</div>
                  </div>
                  <div className="mx-5">
                    <p className="">Skype:</p>
                    <div className="text-muted">fin.win</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="rmt d-flex align-items-center" lg="6">
              <div className="w-100">
                <form
                  onSubmit={(e) => handleSubmit(e)}
                  className=""
                  method="post"
                >
                  <div class="form-group">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      class="form-control"
                      placeholder="Full Name"
                      required
                    />
                  </div>

                  <div class="form-group">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      class="form-control"
                      placeholder="Email Address"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <input
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      rows={"3"}
                      className="form-control"
                      placeholder="Enter Message"
                      required
                    />
                  </div>

                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-xl btn-block btn-primary"
                    >
                      Send a Message
                    </button>
                  </div>

                  <Row className="mt-3 d-flex justify-content-center">
                    <Col lg="12">
                      <Row>
                        <Col>
                          {submitted ? (
                            <div className="alert alert-success">
                              Thanks for submitting your information.
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div class="section bg-light mt-4" id="footer">
        <div class="container">
          <div class="row">
            <div class="col-sm-4">
              <img
                class="img-fluid"
                style={{ height: "80px" }}
                src={require("../../assets/img/logo1.png").default}
                alt=""
                srcset=""
              />

              <p class="mt-3 ml-1 text-muted">Build with Triafirm.</p>
            </div>
            <div class="col-sm-8">
              <h6>Awards and Wins</h6>
              <div className="">
                <img
                  style={{ borderRadius: "2rem" }}
                  className="img-fluid"
                  src={require("../../assets/img/awardlogo.png").default}
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
          <div class=" text-center mt-4">
            <small class="text-muted">
              Copyright © 2023 All rights reserved. Triafirm
            </small>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
